import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "select", "choice", "essay", "single", "multiple" ]

  connect() {
    this.selected()
  }

  selected() {
    this.hideFields()
    switch (this.selectTarget.value) {
      case 'Questions::Essay':
        this.essayTarget.classList.remove('dn')
        break;
      case 'Questions::Select':
        this.choiceTarget.classList.remove('dn')
        this.singleTarget.classList.remove('dn')
        break;
      case 'Questions::Checkbox':
        this.choiceTarget.classList.remove('dn')
        this.multipleTarget.classList.remove('dn')
        break;
    }
  }

  hideFields() {
    this.choiceTarget.classList.add('dn')
    this.essayTarget.classList.add('dn')
    this.singleTarget.classList.add('dn')
    this.multipleTarget.classList.add('dn')
  }
}