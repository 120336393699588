import { Controller } from 'stimulus'
import $ from "jquery";

export default class extends Controller {
  static targets = [ 'select' ]

  connect() {
    this.select2mount()

    document.addEventListener("turbolinks:before-cache", () => {
      this.select2unmount()
    }, { once: true })
  }

  select2mount() {
    const placeholder = $(this.selectTarget).data('placeholder')

    $(this.selectTarget).select2({
      placeholder: placeholder,
      width: '100%',
      minimumResultsForSearch: Infinity
    });
  }

  select2unmount() {
    this.saveState()
    if ($(this.selectTarget).data('select2')) {
      $(this.selectTarget).select2('destroy');
    }
  }

  saveState() {
    let value = $(this.selectTarget).val()

    $(this.selectTarget).find(`option[value="${value}"]`).attr('selected', 'selected');
  }
}