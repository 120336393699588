import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slide"];
  // static values = { index: Question.length };

  initialize() {
    this.index = 0;
    this.showCurrentSlide();
  }

  next() {
    this.index++;
    this.showCurrentSlide();
  }

  previous() {
    this.index--;
    this.showCurrentSlide();
  }

  showCurrentSlide() {
    this.slideTargets.forEach((element, index) => {
      element.hidden = index != this.index;
    });
  }
}
